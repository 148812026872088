
























































































































import Api from "@/common/api/index";
import { getApplyRecordList, getCheckRecordList, getDistributeRecordList, LandBlockItem, LandBlockType } from '@/common/api/modules/smartLocation';
import { defineComponent ,computed,ref, onMounted, reactive, } from '@vue/composition-api';
import LandSelector from './components/LandSelector.vue';
import ListQuerytor from '@/common/ListQuerytorTs';
import dayjs from "dayjs";

export default defineComponent({
  components:{LandSelector},
  setup(props,ctx){
      /**
       * 公共部分
       */
      let showZoom= ref<boolean>(false);
      const dateFormat =  (row:any, column:any, cellValue:any, index:any)=>{
        // console.log({row, column, cellValue, index})
        return dayjs(cellValue).format("YYYY-MM-DD HH:mm")
      }

      /**
       * 地块相关逻辑
       */
      let landBlockList = ref<Array<LandBlockItem>>([]);
      /**  当前地块索引 */
      let index =ref<string|number>('');
      let curBlock = ref<number>(0)
      let curBlockInfo =computed(()=>landBlockList.value[curBlock.value] || {})
      const getLandBlockList = async  ()=>{
        let list = await Api.getLandBlockList();
        landBlockList.value = list
        console.log("获取地块列表",list)
      }
      //跳转楼栋选择
      const  handleToSelectBuilding = (landType?:LandBlockType)=> {
         ctx.root.$router.push({ name: "selectBuilding", params: { land: landType! } });
      } 
      onMounted(()=>getLandBlockList())
      /** 选择地块 */
      function handleSelectLand({index,area}:{index:number,area:LandBlockItem['selectArea']}){
        console.log(`地块选择外部`,{index,area})
        curBlock.value = index;
        showZoom.value  = true
      }

      /**
       * 申请记录相关逻辑
       */
        const  columns = [
          { prop: 'companyName', title: '企业/项目名称'},
          { prop: 'createTime', title: '申请时间'},
          { prop: 'land', title: '地块'},
          { prop: 'build', title: '楼号'},
          { prop: 'room', title: '房号'},
          { prop: 'contactPerson', title: '联系人'},
          { prop: 'phone', title: '联系电话'}
        ]
        /**列表查询器 */
        let recordListQuerytor = ref(new ListQuerytor({api:getApplyRecordList,map:{
          createTime:(value)=>dayjs(value as  unknown as string).format(`YYYY-MM-DD HH:mm`)
        },pager:{size:3}}))

       onMounted(()=>recordListQuerytor.value.query()) 


      /**
       * 房型查看相关逻辑
       */
      /** 当前展示数据 */
      let curHouseTypeNumInfo = ref([]);
      let showHoseTypeDialog = ref(false)
      const handleShowHousTypeNumDialog= (data:string)=>{
        curHouseTypeNumInfo.value = JSON.parse(data)
        console.log(`查看房型数据`,curHouseTypeNumInfo);
        showHoseTypeDialog.value = true;
        
      }

      /**
       * 审核记录相关逻辑
       */
      const showCheckRecordDialog = ref(false)
      const checkRecord = ref([])
      const handleShowCheckRecordDialog = async (id:number)=>{
        let {result}  = await getCheckRecordList(id);
        checkRecord.value = result
        showCheckRecordDialog.value= true;

        
      }

      /**
       * 分配记录相关逻辑
       */
      const showDistributeRecordDialog = ref(false)
      const distributeRecord = ref([])
      const handleShowDistributeRecordDialog =async  (id:number)=>{
        let {result} = await getDistributeRecordList(id);
        distributeRecord.value = result;
        showDistributeRecordDialog.value= true
      }
      return {
        index,
        dateFormat,
        landBlockList,
        showZoom,
        curBlock,
        curBlockInfo,
        columns,
        recordListQuerytor,
        curHouseTypeNumInfo,showHoseTypeDialog,handleShowHousTypeNumDialog,
        showCheckRecordDialog,checkRecord, handleShowCheckRecordDialog,
        showDistributeRecordDialog,distributeRecord,handleShowDistributeRecordDialog,

        handleToSelectBuilding,
        getLandBlockList,
        handleSelectLand,
      }


  }
})





 
 
 



