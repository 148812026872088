

















































import { LandBlockItem } from '@/common/api/modules/smartLocation'
import { defineComponent,onMounted,reactive, ref } from '@vue/composition-api'
import { PropType } from '@vue/runtime-dom'
type point ={
    x:number,
    y:number
}

type line = point[]
export default defineComponent({
    props:{
        areas:Array as PropType<LandBlockItem['selectArea'][]>
    },
    setup(props,ctx) {
      const guide = reactive({
        x:0,
        y:0,
        xp:0,
        yp:0,
        scale:1.5
      })
      const mapRef = ref()
      let plygons = ref([
          {
              id:1,
              label:'nmsl',
              path:[[229,261],[ 215, 280],[190,261],[204,244]]
          },
          {
              id:1,
              label:'nmsl',
              path:[[273,110],[273,122],[270,124],[267,129],[260,129],[258,126],[262,118],[266,104]]
          }
           
      ])
      let points = ref([
          {x:304,y:257}
      ])
      let lines = ref<line[]>([
        //   [{x:228,y:261},{x:214,y:279}] //一条线端的两个端点坐标
      ])
      onMounted(()=>{

      })
      function handleMapMouseMove(e:any){
        const {offsetX,offsetY,x,y,target:{offsetHeight,offsetWidth}} = e
       
        // console.log({offsetX,offsetY,offsetHeight,offsetWidth,x,y,e} )
        guide.x = offsetX;
        guide.y = offsetY;
        guide.xp = (offsetX / offsetWidth) * 100 
        guide.yp = (offsetY / offsetHeight) * 100
        if(flags.drag){
            const differenceX =offsetX - tempCoordinate.x;
            const differenceY =offsetY - tempCoordinate.y;
            const dom = mapRef.value
            dom.scrollLeft += -differenceX
            dom.scrollTop += -differenceY
             console.log({differenceX,differenceY,tempCoordinate,dom} )
        }
      }
      function handleMaoMouseWheel(e:any){
          const {deltaY} = e;
          return
        //   console.log("鼠标滚动",{deltaY});
          let target = guide.scale + (deltaY / 100);
          originCoordinate.x = guide.x;
          originCoordinate.y = guide.y;
          guide.scale = target < 1 ? 1 : (target >5 ? 5 : target)
        //   guide.scale+=(deltaY / 100)
          e.preventDefault();

          return false;
      }
      const flags =reactive({
          drag:false,
          areaDrag:false,
          dragArea:undefined as HTMLBaseElement | undefined
      })
      const tempCoordinate = reactive({
          x:guide.x,
          y:guide.y
      })
      const originCoordinate = reactive({
        x:guide.x,
        y:guide.y
      })
      function handleMouseDown(){
          flags.drag = true;
          //保存鼠标按下时的位置
          tempCoordinate.x = guide.x;
          tempCoordinate.y = guide.y;

      }
      function handleMouseMove(){

      }
      function handleMouseUp(){
          flags.drag =false;

      }
      function computePath(path:number[][],unit:'%' | 'px' = '%'):string{
          const values = path.map(item=>item.map(value=>`${value}${unit}`).join(' ')).join(',')
          return `polygon(${values})`
        //   `polygon(34% 47.31%, 37.79% 50.38%,35.62% 53.46%,32% 50.58%)`
      }
      function handleGuideClick(e:any){
          const {offsetX,offsetY} = e
          console.log(`点击位置`,{offsetX,offsetY})
          points.value.push({x:offsetX , y:offsetY })
          if(points.value.length > 1){
              
          }
      }
      function handlePointClick(index:number,item:{x:number,y:number},e:any){
        console.log(index,item)
        
        e.preventDefault();
      }
    
    function computedLinePath(line:line):string{
        const path = line.map((point,index)=>{
            let p =[[point.x-1,point.y],[point.x + 1,point.y]]
            return index ? p.reverse() : p
        })
        let res =  computePath(path.flat(),'px')
        // console.log(`坐标展开`,path.flat(),`坐标计算结果`,res,`展开前`,path)

        return res;
    }
    function handelAreaMouseMove(e:any){
        const {offsetX,offsetY,x,y,target:{offsetHeight,offsetWidth}} = e
        if(flags.areaDrag){
            // flags.dragArea!.style.left = offsetX + 'px'
            // flags.dragArea!.style.top = offsetY + 'px'
            console.log(`handelAreaMouseMove`,{offsetX,offsetY,offsetHeight,offsetWidth,x,y,e} )
        }
    }
    function handleAreaMouseDown(e:any){
        flags.areaDrag= true;
        flags.dragArea = e.target
    }
    function handleAreaMouseUp(e:any){
        flags.areaDrag= false;
        flags.dragArea = undefined;

    }
    function computeLineStyle(line:line){
        
        return {
                clipPath:computedLinePath(line),
                transform: `scale(${guide.scale})`,
                transformOrigin: `${originCoordinate.x}px ${originCoordinate.y}px`
        }

     }  

    /**
     * 选区部分逻辑
     */
    const areas =ref<LandBlockItem['selectArea'][]>()
    
    onMounted(()=>{
        areas.value = props.areas
    })
    let curActiveArea = ref()
    const handleSelectArea = (index:number,area:LandBlockItem['selectArea'])=>{
        console.log(`区域选择`,{index,area})
        curActiveArea.value = index
        ctx.emit("change",{index,area})
    }

        // {width:87,height:85,left:166,top:219,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land1.png'},
        // {width:80,height:98,left:210,top:58,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land2.png'},
        // {width:51,height:69,left:240,top:82,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land3.png'},
        // {width:71,height:62,left:252,top:77,bg:'http://lftweb.oss-cn-hangzhou.aliyuncs.com/static/smartlocaltion/area/land4.png'}
   
    
    


    return {
        areas,curActiveArea,handleSelectArea,
        guide,
        flags,
        mapRef,
        plygons,
        originCoordinate,
        points,
        lines,
        handleAreaMouseDown,
        handleAreaMouseUp,
        handelAreaMouseMove,
        computeLineStyle,
        handlePointClick,
        handleGuideClick,
        computePath,
        handleMapMouseMove,
        handleMaoMouseWheel,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp
        }
    },
})
